import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';

import ScrollButton from "../components/ScrollButton";

const dataImage = "https://vale-outdoors-prod-images.s3.us-east-2.amazonaws.com/deer-data.png";
const backgroundImage = "https://vale-outdoors-prod-images.s3.us-east-2.amazonaws.com/deer-sunset-edited.jpg";

function DeerVisionLanding({isMobile }) {

    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Deer Vision';
    });

    return(
        <>
            <div style={isMobile ? { "minHeight": "100vh", "width":"100%", "overflow":"hidden" } : { "minHeight": "100%", "width":"100%" }} className="w3-display-container">
                <img style={isMobile ? { "marginLeft": "60%", "transform": "translateX(-50%)" } : { "width": "100%" }} alt="Site Banner" src={backgroundImage} />
                <div className="w3-display-left" style={isMobile ? { "marginTop": "128px", "paddingLeft": "64px" } : { "paddingLeft": "64px" }}>
                    <div style={{ "width": "240px" }}>
                        <div className={isMobile ? "w3-opacity-min" : ""} style={isMobile ? { "background": "lightgrey", "borderRadius": "12px", "padding": "12px" } : {}}>
                            <p className={isMobile ? "" : "white"} style={isMobile ? { "marginLeft": "16px", "marginRight": "16px", "fontSize": "18px"} : { "fontSize": "25px" }}>Precision Tracking, Seamless Hunting</p>
                            <p className={isMobile ? "" : "white"} >Empowering Your Pursuit with Smart Software Solutions.</p>
                        </div>
                        <div style={{ "width": "100%", "display": "flex", "justifyContent": "right" }}>
                            <button style={isMobile ? { "paddingLeft": "16px", "paddingRight": "16px", "marginTop": "16px" } : { "paddingLeft": "16px", "paddingRight": "16px" }} className="main-button" onClick={() => navigate("/register")}>Get Access</button>
                        </div>
                    </div>
                </div>
            </div>
            {isMobile ? null : (
                <ScrollButton anchor="learn" text="Learn More" />
            )}
            <div id="learn" style={{ "height": "100vh", "width":"100%", "display": "flex", "alignItems": "center" }} className="w3-display-container">
                <div style={isMobile ? { "width": "100%" } : { "display": "flex" }}>
                        {isMobile ? (
                            <div style={{ "width": "100%", "display": "flex", "justifyContent": "center" }}>
                                <img style={{ "width": "100%", "padding": "8px" }} alt="Tracing Deer with Bounding Boxes and Weather Data Plots" src={dataImage} />
                            </div>
                        ) : (
                            null
                        )}
                        <div style={isMobile ? { "display": "flex", "justifyContent": "center"} : { "display": "flex", "alignItems": "center", "marginLeft": "128px" }}>
                            <div style={{ "width": "360px",}}>
                                <p style={isMobile ? { "marginLeft": "16px", "marginRight": "16px", "fontSize": "18px"} : { "fontSize": "25px" }}>Where Precision Meets Prey</p>
                                <p>Utilizing your trail camera image data, location-based historical weather data, and federated learning find your optimal hunting ground.</p>
                                <div style={{ "width": "100%", "display": "flex" }}>
                                    <button style={{ "paddingLeft": "16px", "paddingRight": "16px" }} className="main-button" onClick={() => navigate("/register")}>Try for Free</button>
                                </div>
                            </div>
                            
                        </div>
                        {isMobile ? (
                            null
                        ) : (
                            <div style={{ "width": "100%", "display": "flex", "justifyContent": "right", "marginRight": "128px" }}>
                                <img style={{ "width": "75%" }} alt="Tracing Deer with Bounding Boxes and Weather Data Plots" src={dataImage} />
                            </div>
                        )}
                </div>
            </div>
        </>        
    );
}

DeerVisionLanding.propTypes = {
    isMobile: PropTypes.bool
};

export default DeerVisionLanding;