import Axios from "axios";
import { packShippingBoxes } from "../3DBinPacking/packing";
import { parse as exifParse } from 'exifr'
import Resizer from "react-image-file-resizer";
import { Buffer } from 'buffer';

// Deep copy doesn’t reflect changes made to the new/copied object in the original object.
export const createDeepClone = (object, transferables) => {
  let cloneObject = '';
  if(transferables) {
    cloneObject = structuredClone(object, { transfer: transferables });
  } else {
    cloneObject = structuredClone(object);
  }
  return(cloneObject);
};

export const getObjectValue = (object, key) => {
  let result = false;

  if(object && object.hasOwnProperty(key) && (object[key] || (typeof object[key] === "boolean" && object === false) || typeof object[key] === "number")) {
    result = object[key];
  }
  return result;
};

//validateInput Child function
export const includes = (container, value, toLowerCase) => {
    var returnValue = false;
    var pos = null;
    if (container) {
      if (toLowerCase === true) {
        pos = container.toLowerCase().indexOf(value);
      } else {
        pos = container.indexOf(value);
      }
      if (pos >= 0) {
        returnValue = true;
      }
    }
    return returnValue;
    
};

//validateInput Child function
export const doesNotInclude = (container, value) => {
  var returnValue = false;
  var pos = container.indexOf(value);
  if (pos < 0) {
    returnValue = true;
  }
  return returnValue;
};

export const getType = (p) => {
  if (Array.isArray(p)) return 'array';
  else if (typeof p == 'string') return 'string';
  else if (p != null && typeof p == 'object') return 'object';
  else return 'other';
};

//validateInput Child function
export const checkLength = (container, value) => {
  let checkValue = value;
  if(typeof value === "string" && !isNaN(value)) {
    checkValue = parseInt(value);
  }
  if(container && container.length === checkValue) {
    return true;
  } else {
    return false;
  }
};

//validateInput Child function
export const hasNumber = (input) => {
  return /\d/.test(input);
};

//validateInput Child function
export const checkArray = async (array) => {
  let passing = true;
  await array.forEach(async(item) => {
    if(item === false) {
      passing = false;
    }
  });
  if(passing === false) {
    return false;
  } else {
    return true;
  }
};

export const hasCapitalLetter = (str) => {
  return /[A-Z]/.test(str);
};

export const hasWhiteSpace = (str) => {
  return /\s/g.test(str);
};

export const hasANumber = (str) => {
  return /[0-9]+/.test(str);
};

export const isCamelCase = (str) => {
  let isCamel = hasCapitalLetter(str);
  if(isCamel) {
    let hasSpace = hasWhiteSpace(str);
    let spaceIndex = '';
    if(hasSpace) {
      spaceIndex = firstSpace(str);
    }
    isCamel = firstCapitalLetter(str, 0);
    if(typeof(spaceIndex) === "number" && spaceIndex < isCamel) {
      isCamel = false;
    }
  } else {
    isCamel = hasANumber(str);
    if(isCamel) {
      isCamel  = firstNumber(str, 0);
    }
  }
  return isCamel;
};

export const firstCapitalLetter = (str, i) => {
  if (i === str.length) {
    return 0;
  }
  if (str[i] !== ' ' && str[i] === str[i].toUpperCase()) {
    return i;
  }
  return firstCapitalLetter(str, i+1);
};

export const firstSpace = (str) => {
  return(str.indexOf(' '));
};

export const firstNumber = (str, i) => {
  if (i === str.length) {
    return 0;
  }
  if (!isNaN(str[i])) {
    return i;
  }
  return firstNumber(str, i+1);
};

export const camelCaseToHumanReadable = (str) => {
  let returnString = '';
  let isCamel = isCamelCase(str);
  if(isCamel) {
    returnString = `${capitalizeFirstLetter(str.slice(0, isCamel))} ${capitalizeFirstLetter(str.slice(isCamel))}`;
  } else {
    returnString = capitalizeFirstLetter(str);
  }
  return returnString;
};

export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const downloadFile = (link, fileName) => {
  const downloadLink = document.createElement("a");
  downloadLink.href = link;
  downloadLink.download = fileName;
  downloadLink.click();
};

export const combineItemsInCart = async (order) => {
  const set = new Set();
  let newOrder = [];
  await Promise.all(order.map((product) => {
    let newProduct = { ...product };
    if(set.has(`${newProduct.name}_${newProduct.color}`)) {
      let index = newOrder.findIndex((item) => item.name === newProduct.name && item.color === newProduct.color);
      Object.assign(newOrder[index], { "quantity": newOrder[index].quantity + newProduct.quantity });
    } else {
      set.add(`${newProduct.name}_${newProduct.color}`);
      newOrder.push(newProduct);
    }
    return true;
  }));
  return newOrder;
};

export const adjustShippingPrice = (amount) => {
  let newAmount = parseFloat(amount);

  return parseFloat(Math.ceil(newAmount + (newAmount * .1)));
};

// triggerShippingFunction Child Function
export const getBoxes = async (products) => {
  let boxOptions = [{ "xdist": 6, "ydist": 10, "zdist": 0.5, "provider": "USPS", "name": "Bubble Mailer", "designation": "minimum", "weight": 10 },
                      { "xdist": 8.625, "ydist": 5.375, "zdist": 1.625, "provider": "USPS", "name": "Priority Small Box", "designation": "midsize", "weight": 30 },
                      { "xdist": 11.25, "ydist": 6, "zdist": 8.75, "provider": "USPS", "name": "Priority Medium Box", "designation": "maximum", "weight": 50 }];

  boxOptions.map((box) => {
    Object.assign(box, { "xdist": box.xdist * 25.4, "ydist": box.ydist * 25.4, "zdist": box.zdist * 25.4 });
    return true;
  });

  let packedBoxes = [];
  packedBoxes = packShippingBoxes(boxOptions, products);
  // console.log(packedBoxes);

  packedBoxes = packedBoxes.map((box) => {
    let newBox = box;
    Object.assign(newBox, { "weight": box.totalWeight + box.weight });
    return newBox;
  })

  return packedBoxes;
};

export const triggerShippingFunction = async (type, shippingObject, senderAddress) => {
  let postData = {};
  let packedBoxes = '';
  let addressFrom = JSON.parse(process.env.REACT_APP_ADDRESS_FROM);
  let addressTo = '';
  if (type === "estimate") {
    //Address Prototype
    addressTo = {
      "name":`${shippingObject.firstName} ${shippingObject.lastName}`,
      "street1": shippingObject.address1,
      "city": shippingObject.city,
      "state": shippingObject.state,
      "zip": shippingObject.zip,
      "country": shippingObject.country, //iso2 country code
      "email": shippingObject.email,
    };

    if(shippingObject.company) {
      Object.assign(addressTo, { "company": shippingObject.company });
    }
    if(shippingObject.address2) {
      Object.assign(addressTo, { "street2": shippingObject.address2 });
    }

    let parcelArray = [];
    if(shippingObject.hasOwnProperty("products") && shippingObject.products && shippingObject.products.length > 0 && !shippingObject.hasOwnProperty("parcels")) {
      let filteredProducts = shippingObject.products.filter((product) => !product.hasOwnProperty("preorder") || (product.hasOwnProperty("preorder") && product.preorder === false));
      if(filteredProducts.length === 0) {
        return true;
      }
      parcelArray = await getBoxes(filteredProducts);
      packedBoxes = parcelArray;
    }

    if(shippingObject.hasOwnProperty("parcels") && shippingObject.parcels && shippingObject.parcels.length > 0) {
      parcelArray = shippingObject.parcels;
    }

    //Parcel Prototype
    let parcels = await Promise.all(parcelArray.map((parcel) => {
      return({
        "length": parcel.xdist.toFixed(2),
        "width": parcel.ydist.toFixed(2),
        "height": parcel.zdist.toFixed(2),
        "distance_unit": "mm",
        "weight": parcel.weight.toFixed(2),
        "mass_unit": "g"
      });
    }));
    // console.log(parcels);

    if(senderAddress) {
      //Address Prototype
      addressFrom = {
        "name":`${senderAddress.firstName} ${senderAddress.lastName}`,
        "street1": senderAddress.address1,
        "city": senderAddress.city,
        "state": senderAddress.state,
        "zip": senderAddress.zip,
        "country": senderAddress.country, //iso2 country code
        "email": senderAddress.email,
      };

      if(senderAddress.company) {
        Object.assign(addressFrom, { "company": senderAddress.company });
      }
      if(senderAddress.address2) {
        Object.assign(addressFrom, { "street2": senderAddress.address2 });
      }
    }

    postData = {
      "addressFrom": addressFrom, 
      "addressTo": addressTo, 
      "parcels": parcels
    };
  } else if (type === "label") {
    postData = {
      ...postData,
      ...shippingObject
    };
  }
  // console.log(type);
  // console.log(postData);

  const response = await makeNetworkRequest("post", `shipping/${type}`, postData, "netlify");
  // console.log(response);

  let returnObject = false;
  if(type === "estimate" && response && response.data && response.data.rates && response.data.rates.length > 0) {
    let rate = response.data.rates.filter((rate) => rate.attributes && rate.attributes.length > 0 && rate.attributes[0] === 'BESTVALUE' );
    if(rate && rate.length > 0) {
      returnObject = [rate[0], response.data];
    } else if (rate && rate.length === 0) {
      returnObject = [response.data.rates[0], response.data];
    }
    if(returnObject && packedBoxes) {
      returnObject.push(packedBoxes);
    }
  } else if(type ==="label" && response && response.data && response.data.results) {
    let labels = await Promise.all(response.data.results.map((label) => {
      return (label.label_url);
    }));
    let tracking_numbers = await Promise.all(response.data.results.map((label) => {
      return (label.tracking_number);
    }));
    returnObject = {
      labels,
      tracking_numbers
    }
    // console.log(returnObject);
  }
  return returnObject;
};

export const triggerSendMessageFunction = async (endPoint, object) => {
    const response = await makeNetworkRequest("post", `emailNotifications/${endPoint}`, object, "netlify");
    // console.log(response);
    
    return response;
};

export const triggerAWSDynamoDBFunction = async (httpRequestType, endPoint, postData) => {
  let fullEndpoint = `awsDynamoDB/${endPoint}`;
  let response = await makeNetworkRequest(httpRequestType, fullEndpoint, postData, "netlify");
  // console.log(response);
  if((endPoint === "add-distributor" && response) || (response && response.hasOwnProperty("data") && response.data.length > 0)) {
    return response.data;
  } else {
    return false;
  }
};

export const triggerPrivacyPreferencesFunction = async (httpRequestType, endPoint, postData) => {
  let fullEndpoint = `privacyPreferences/${endPoint}`;
  let response = await makeNetworkRequest(httpRequestType, fullEndpoint, postData, "netlify");
  if((endPoint === "update-preferences" && response) || (response && response.hasOwnProperty("data") && response.data.length > 0)) {
    return response.data;
  } else {
    return false;
  }
};

export const triggerAuth0Function = async (httpRequestType, endPoint, postData) => {
  let fullEndpoint = `auth0/${endPoint}`;
  let response = await makeNetworkRequest(httpRequestType, fullEndpoint, postData, "netlify");
  // console.log(response);
  if(response && response.hasOwnProperty("data") && response.data) {
    return response.data;
  } else {
    return false;
  }
};

export const makeNetworkRequest = async (httpRequestType, endPoint, postData, type, lambdaUrl) => {
  let url = "";
  let encryptPostData = true;
  switch (type) {
    case "netlify":
      url = "/.netlify/functions/"
      break;
    case "AWS":
      if(process.env.REACT_APP_NODE_ENV === "development") {
        url = "/.netlify/functions/devProxy/"
      } else {
        url = lambdaUrl
      }
      break;
    default:
      url = "/.netlify/functions/proxy/";
      encryptPostData = false;
      break;
  }

  if(process.env.REACT_APP_NODE_ENV === "development") {
    url = `${process.env.REACT_APP_DEVELOPMENT_FUNCTIONS_ENDPOINT}${url}`;
  }

  let confirmedPostData = postData;
  if(httpRequestType === "post" && encryptPostData) {
    confirmedPostData = { "data": JSON.stringify(postData) };
  }

  try {
    let response = await Axios[httpRequestType](`${url}${endPoint}`, confirmedPostData);
    // console.log(response);
    if(response.data && typeof response.data === "string") {
      let data = JSON.parse(response.data);
      Object.assign(response, { "data": data });
    }
    return response;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const getUnique = (items, value) => {
  return [...new Set(items.map((item) => item[value]))];
};

export const getAllProducts = async () => {
  let rawProducts = await triggerAWSDynamoDBFunction("get", "all-products");
  return rawProducts;
};

export const getStoreFrontProducts = async () => {
  let rawProducts = await triggerAWSDynamoDBFunction("get", "store-front-products");
  // console.log(rawProducts);
  return rawProducts;
};

export const getDistributorInfo = async (storeCode) => {
  let distributorInfo = await triggerAWSDynamoDBFunction("post", "confirm-store-code", { storeCode });
  // console.log(distributorInfo);

  if(distributorInfo && distributorInfo.length > 0) {
    distributorInfo = distributorInfo[0];
  }

  return distributorInfo;
};

export const getAllDistributors = async () => {
  let distributors = await triggerAWSDynamoDBFunction("get", "all-distributors");
  // console.log(distributors);
  return distributors;
};

export const assignDistributorInfoProperties = async (products, distributorInfo) => {
  let productsClone = [...products];
  if(distributorInfo && distributorInfo.hasOwnProperty("priceList") && distributorInfo.priceList && productsClone && productsClone.length > 0) {
    productsClone.forEach((product) => {
      if(distributorInfo.priceList.hasOwnProperty(product.productId)) {
        let distributorInfoProduct = distributorInfo.priceList[product.productId]
        if(distributorInfoProduct.hasOwnProperty("price") && distributorInfoProduct.price) {
          Object.assign(product, { "price": distributorInfoProduct.price });
        }
        if(distributorInfoProduct.hasOwnProperty("minQuantity") && distributorInfoProduct.minQuantity) {
          Object.assign(product, { "minQuantity": distributorInfoProduct.minQuantity });
        }
      }
    });
  }

  return productsClone;
};

export const addNewDistributor = async (distributorInfo) => {
  let newDistributor = await triggerAWSDynamoDBFunction("post", "add-distributor", { distributorInfo });
  // console.log(newDistributor);
  let result = false;
  if(newDistributor) {
    result = true
  }
  return result;
}

export const getPrivacyPreferences = async (email) => {
  let privacyPreferences = await triggerPrivacyPreferencesFunction("post", "get-preferences", { email });
  let result = false;
  if(privacyPreferences && privacyPreferences.length > 0) {
    result = privacyPreferences[0];
  }
  return result;
};

export const updatePrivacyPreferences = async (privacyPreferences) => {
  let updatedPrivacyPreferences = await triggerPrivacyPreferencesFunction("post", "update-preferences", { privacyPreferences });
  let result = false;
  if(updatedPrivacyPreferences) {
    result = true
  }
  return result;
};

export const validateInput = async (validateArray) => {
  window.includes = includes;
  window.checkLength = checkLength;
  window.hasNumber = hasNumber;
  /* Validate Object Prototype:
  validateObject = {
    name: "",
    displayName: "",
    input: "",
    type: "",
    specialConditions: []
  } */
  let newValidateArray = [...validateArray];
  let isAllValid = await Promise.all(newValidateArray.map(async (validateObject) => {
    //Check for standard text properties, i.e. existence, length, profanity, etc.
    if(validateObject.type === "text") {
      //Start of existence check, exempt address2 from this check
      if(!validateObject.input && validateObject.name !== "address2" && validateObject.name !== "company") {
        Object.assign(validateObject, { errorDisplay: `Please enter a ${validateObject.displayName}`, errorCode: "1" });
        return false;
      }
      //End of existence check
      //Start Special Characters check
      //let specialChars = `\`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~`;
      let specialChars = ['/', '#', '%', '^', '&', '*', '(', ')', '_', '+', '=', '[', ']', '{', '}', ';', ':', '"', '|', ',', '<', '>', '?', '~', '\\'];
      let result = await Promise.all(specialChars.map(specialChar => {
        if (includes(validateObject.input, specialChar)) {
          return false;
        } else {
          return true;
        }
      }));
      //console.log(result);
      let returnData = await checkArray(result);
      if(returnData === false) {
        return false;
      }
      //End of special characters check
      //Start of Profanity Check
      let profanity = ["nigg", "n1gg", "shit", "damn", "fuck", "cunt", "whore", "bastard",
      "penis", "p3n1s", "p3nis", "pen1s", "vagina", "dildo", "arse", "damn", "goddamn", 
      "goddam", "sod-off", "asshole", "sh1", " ass ", " piss ", "bitch", "b1t", "bollocks",
      " feck ", " dick ", " twat ", " prick ", " pussy ", "pu33"];
      let isProfane = await Promise.all(profanity.map((word) => {
          if(includes(validateObject.input, word)) {
            Object.assign(validateObject, { errorDisplay: `Please enter an appropriate ${validateObject.displayName}`, errorCode: "2" });
            return false;
          } else { 
            return true;
          }
      }));
      returnData = await checkArray(isProfane);
      if(returnData === false) {
        return false;
      }
      //End of Profanity Check
    }
    //End of text properties check
    //Check for number properties i.e existence, parse
    else if (validateObject.type === "number") {
      //Start of existence check
      if(!validateObject.input) {
        Object.assign(validateObject, { errorDisplay: `Please enter a ${validateObject.displayName}`, errorCode: "3" });
        return false;
      }
      //End of existence check
      //Start of number check
      if(isNaN(validateObject.input)) {
        Object.assign(validateObject, { errorDisplay: `Please enter a valid ${validateObject.displayName}`, errorCode: "4" });
        return false;
      }
      //End of number check
    }
    //End of number properties check
    //Check for dropdown properties
    else if (validateObject.type === "dropdown") {
      //Start of existence check
      if(!validateObject.input) {
        Object.assign(validateObject, { errorDisplay: `Please enter a ${validateObject.displayName}`, errorCode: "5" });
        return false;
      }
      //End of existence check
    } else if (validateObject.type === "stock") {
      //Start of existence check
      if(!validateObject.input) {
        Object.assign(validateObject, { errorDisplay: `Item cannot be added to cart, out of stock`, errorCode: "5" });
        return false;
      }
      //End of existence check
    } else if (validateObject.type === "boolean") {
      //Start of existence check
      if(typeof(validateObject.input) !== "boolean") {
        Object.assign(validateObject, { errorDisplay: `Incorrect values detected ${validateObject.displayName}`, errorCode: "5" });
        return false;
      }
      //End of existence check
    }
    //End of dropdown properties check
    //Check for special conditions
    //console.info(validateObject.input);
    //console.log(validateObject.specialConditions);
    //console.log(validateObject.specialConditions.length);
    if(validateObject.specialConditions && validateObject.specialConditions.length > 0) {
      let isValidSC = await Promise.all(validateObject.specialConditions.map((specialCondition, index) => {
        //If special condition is a function call
        if(includes(specialCondition, "(") && includes(specialCondition, ")")) {
          let sCondition = specialCondition;
          let notFN = false;
          if(includes(specialCondition, "!") && specialCondition.substring(0,1) === "!") {
            sCondition = specialCondition.substr(1);
            notFN = true;
          }
          let fnCall = sCondition.split("(")[0]; 
          let fnParameters = '';
          if(includes(sCondition.split("(")[1].split(")")[0],",")){
            fnParameters = sCondition.split("(")[1].split(")")[0].split(",")[1];
          }
          if(notFN) {
            //If the function result should be false and comes back true do this
            //console.log(`!${fnCall}(${input},${fnParameters}) = ${window[fnCall](input, fnParameters)}`);
            if((window[fnCall](`${validateObject.input}`, `${fnParameters}`))) {
              Object.assign(validateObject, { errorDisplay: `Please enter a valid ${validateObject.displayName}`, errorCode: `SC False - !${fnCall}(${validateObject.input},${fnParameters})` });
              return false;
            }
          } else {
            //Else if the function result should be true and comes back false do this
            //console.log(`${fnCall}(${input},${fnParameters}) = ${window[fnCall](input, fnParameters)}`);
            if(!(window[fnCall](`${validateObject.input}`, `${fnParameters}`))) {
              Object.assign(validateObject, { errorDisplay: `Please enter a valid ${validateObject.displayName}`, errorCode: `SC True - ${fnCall}(${validateObject.input},${fnParameters})` });
              return false;
            }
          }
          return true;
        } else {
          //Handle special conditions that don't use function calls
          return false;
        }
      }));
      //console.info(isValidSC);
      let returnData = await checkArray(isValidSC);
      if(returnData === false) {
        return false;
      }
    }
    return true;
  }));
  //console.info(isAllValid);
  let returnData = await checkArray(isAllValid);
  return [returnData, newValidateArray];
};

export const setObjectFunc = (setObject, updateObject, nestedObjectKeys) => {
  setObject((prevObject) => {
    // console.log(prevObject);

    // Handle setting item of type object
    if(getType(prevObject) === "object") {
      let objectToBeUpdated = prevObject;
      if(nestedObjectKeys && nestedObjectKeys.length > 0) {
        nestedObjectKeys.forEach((key, index) => {
          if(objectToBeUpdated.hasOwnProperty(key)) {
            objectToBeUpdated = objectToBeUpdated[key];
            if(index === nestedObjectKeys.length - 1) {
              Object.assign(objectToBeUpdated, { ...updateObject });
            }
          }
        });
        return({ ...prevObject });
      } else {
        return({ ...prevObject, ...updateObject });
      }
    }
  });
};

export const saveEncryptedDataToLocalStorage = async (key, data) => {
  let encryptedString = data;
  // console.log(encryptedString);
  localStorage.setItem(key, encryptedString);
  return true;
};

export const getEncryptedDataFromLocalStorage = async (key) => {
  let data = localStorage.getItem(key);
  if(data) {
    // console.log(data);
    return data;
  }
  return false;
};

export const getDataFromLocalStorage = (key) => {
  let data = localStorage.getItem(key);
  if(data) {
    return data;
  }
  return false;
};

export const reformatObjectToAnotherObject = (object, templateObject) => {
  let returnObject = {};
  let templateObjectKeys = Object.keys(templateObject);
  templateObjectKeys.forEach((key) => {
    Object.assign(returnObject, { [key]: object[key] });
  });

  return returnObject;
};

export const areObjectsEqual = (obj1, obj2) => {
  const obj1Keys = Object.keys(obj1);
  const obj2Keys = Object.keys(obj2);

  if(obj1Keys.length !== obj2Keys.length) {
    return false;
  }

  for(let objKey of obj1Keys) {
    if(obj1[objKey] !== obj2[objKey]) {
      if(typeof obj1[objKey] == "object" && typeof obj2[objKey] == "object") {
        if(!areObjectsEqual(obj1[objKey], obj2[objKey])) {
          return false;
        }
      } 
      else {
        // console.log(`${obj1[objKey]} !== ${obj2[objKey]}`);
        return false;
      }
    }
  }

  return true;
};

export const checkForManufacturerWorkflow = (products) => {
  let manufacturerAddress = '';
  // Check for manufacturer based mailing
  let allHaveAlternateAddress = true;
  let allHaveSameAlternateAddress = true;
  products.forEach((product) => {
      if(product.hasOwnProperty('manufacturer') && product.manufacturer) {
          if(!manufacturerAddress) {
              manufacturerAddress = product.manufacturer;
          } else {
              let areEqual = areObjectsEqual(manufacturerAddress, product.manufacturer);
              if(!areEqual) {
                  allHaveSameAlternateAddress = false;
              }
          }
      } else {
          allHaveAlternateAddress = false;
      }
  });
  if(!(allHaveAlternateAddress && allHaveSameAlternateAddress)) {
    manufacturerAddress = '';
  }
  return manufacturerAddress;
};

export const scrollTo = (anchorId, behavior) => {
  let anchorItem = document.getElementById(anchorId);
  if(anchorItem) {
    anchorItem.scrollIntoView({ "behavior": behavior });
  }
};

export const updateProfile = async (updatedProfile, currentProfile) => {
  // console.log(updatedProfile);
  // console.log(currentProfile);

  let updatedMetaData = await generateMetaDataUpdates(updatedProfile);
  // console.log(updatedMetaData);

  let response = await triggerAuth0Function("post", "update-profile", { updatedMetaData, "userId": currentProfile.sub });
  return response;
};

export const generateMetaDataUpdates = async (user) => {
  let updatedMetadata = {};
  await Promise.all(Object.keys(user.defaultProfile).map(async(key) => {
    if((key === "locations") && user[key]) {
      let newObject = {};
      await Promise.all(Object.keys(user[key]).map((nestedKey) => {
        let newKeyName = nestedKey.replaceAll(".", ":");
        let newKeyValuePair = { [newKeyName]: { ...user[key][nestedKey] } };
        Object.assign(newObject, { ...newKeyValuePair }); 

        return true;
      }));
      Object.assign(updatedMetadata, { [key]: { ...newObject } });
    } else {
      Object.assign(updatedMetadata, { [key]: user[key] });
    }
    return true;
  }));

  return updatedMetadata;
};

export const generateDateLabels = async (lengthOfTime, today) => {
  let generatedDays = [];
  return await new Promise((resolve) => {
    for(let i = 0; i < lengthOfTime; i++) {
      let calculatedDate = new Date(today.getTime() + (i * 24 * 3600 * 1000));
      let dateString = `${calculatedDate.getMonth() + 1}-${calculatedDate.getDate()}`;
      generatedDays.push(dateString);
    }
    if(generatedDays.length === lengthOfTime) {
      resolve(generatedDays);
    }
  });
};

export const convertUnits = (value, conversion) => {
  let convertedValue = value;
  switch(conversion) {
    case 'm/s to mph':
      convertedValue = value * 2.237;
      break;
    default:
      throw new Error("Invalid unit conversion specified!");
  }

  return convertedValue;
};

export const convertData = (dataToBeConverted, conversionSchema) => {
  let convertedData = dataToBeConverted;
  if (conversionSchema) {
    convertedData = dataToBeConverted.map((item) => { return({ ...item, "value": convertUnits(item.value, conversionSchema.conversion) }); });
  }

  return convertedData
};

export const normalizeData = async (dataToBeNormalized, conversionSchema) => {
  let min = Math.min(...dataToBeNormalized.map(item => item.value));
  let max = Math.max(...dataToBeNormalized.map(item => item.value));

  let normalizedData = await Promise.all(dataToBeNormalized.map((dataPoint) => {
    let date = new Date(dataPoint.date);
    let x = convertLocaletoISOFormat(date);

    let y = dataPoint.value;
    if(typeof y === "number") {
      if (conversionSchema && getObjectValue(conversionSchema, "normalize")) {
        y = (y - min) / (max - min);
      } else if (conversionSchema) {
        y = convertUnits(y, conversionSchema.conversion);
      }
    } else if (isDate(y)) {
      y = convertLocaletoISOFormat(new Date(y))
    }
    return { x, y };
  }));
  
  return normalizedData;
};

export const prepopulateData = async (dataTemplate, unitValue) => {
  let populatedData = await Promise.all(dataTemplate.map((dataPoint) => {
    let date = new Date(dataPoint.date);
    let x = convertLocaletoISOFormat(date);

    let y = unitValue;
    return { x, y };
  }));
  
  return populatedData;
};

export const convertLocaletoISOFormat = (date, fileName) => {
  let month = ((date.getMonth() + 1) < 10) ? "0" + (date.getMonth() + 1).toString() : (date.getMonth() + 1);
  let day = (date.getDate() < 10) ? "0" + date.getDate().toString() : date.getDate();
  let hour = (date.getHours() < 10) ? "0" + date.getHours().toString() : date.getHours();
  let minute = (date.getMinutes() < 10) ? "0" + date.getMinutes().toString() : date.getMinutes();
  let year = date.getFullYear();
  let newDateString = `${month}-${day} ${hour}:${minute}`;
  if(fileName) {
    newDateString = `${month}_${day}_${year}_${hour}_${minute}`;
  }
  return newDateString;
};

export const convertLocaletoFullISOFormat = (date, fileName) => {
  let year = date.getFullYear();
  let month = ((date.getMonth() + 1) < 10) ? "0" + (date.getMonth() + 1).toString() : (date.getMonth() + 1);
  let day = (date.getDate() < 10) ? "0" + date.getDate().toString() : date.getDate();
  let hour = (date.getHours() < 10) ? "0" + date.getHours().toString() : date.getHours();
  let minute = (date.getMinutes() < 10) ? "0" + date.getMinutes().toString() : date.getMinutes();
  let second = (date.getSeconds() < 10) ? "0" + date.getSeconds().toString() : date.getSeconds();
  let newDateString = `${month}-${day}-${year} ${hour}:${minute}:${second}`;
  if(fileName) {
    newDateString = `${month}_${day}_${year}_${hour}_${minute}_${second}`;
  }
  return newDateString;
};

export const roundToHour = (date) => {
  let p = 60 * 60 * 1000; // milliseconds in an hour
  return new Date(Math.floor(date.getTime() / p ) * p);
};

export const isDate = (date) => {
  return (new Date(date) !== "Invalid Date") && !isNaN(new Date(date));
};

export const validateProducts = async (productsToBeValidated, products, distributorInfo) => {
  let invalidProductFound = false;
  await Promise.all(productsToBeValidated.map(async (product) => {
    try {
      let productsClone = [...products];
      if(distributorInfo) {
          productsClone = await assignDistributorInfoProperties(productsClone, distributorInfo);
          // console.log(productsClone);
      }
      let index = productsClone.findIndex((databaseProduct) => databaseProduct.name === product.name);
      // console.log(index);

      if(index >= 0) {
        let formattedObject = reformatObjectToAnotherObject(product, products[index]);
        // console.log(formattedObject);

        let objectsAreEqual = areObjectsEqual(products[index], formattedObject);
        // console.log(objectsAreEqual);

        if(!objectsAreEqual && getObjectValue(formattedObject, "types") && formattedObject.types.length > 0) {
          let priceDoesMatchType = false;
          formattedObject.types.map((type) => {
              if(type.price === formattedObject.price) {
                  priceDoesMatchType = true;
                  Object.assign(formattedObject, { "price": productsClone[index].price });
              }
              return true;
          });
          if(priceDoesMatchType) {
              objectsAreEqual = areObjectsEqual(productsClone[index], formattedObject);
              // console.log(objectsAreEqual);
          }
      }

        if(objectsAreEqual) {
          return true;
        }

        invalidProductFound = true;
        return false;
      } else {
        invalidProductFound = true;
        return false;
      }
    } catch (error) {
      invalidProductFound = true;
      return false;
    }
  }));

  return !invalidProductFound;
};

export const modifyOrder = async (e, order, setOrder) => {
  // console.log(e);

  // Check to see if anything has changed for shipping rate evaluation
  if(e.hasOwnProperty("products") && e.products) {
    Object.assign(e, { "shippingInfoHasChanged": true });
  }

  if(e && e.hasOwnProperty("shippingInfo")) {
    let orderShippingInfoClone = { ...order.shippingInfo };
    delete orderShippingInfoClone.products;
    delete orderShippingInfoClone.country;

    let eShippingInfoClone = { ...e.shippingInfo };
    delete eShippingInfoClone.products;

    if(!deepEqual(orderShippingInfoClone, eShippingInfoClone)) {
      Object.assign(e, { "shippingInfoHasChanged": true });
    }  
  } 
  
  let updateObject = {};
  if(getObjectValue(e, "target")) {
    Object.assign(updateObject, { [e.target.name]: e.target.value });
  } else {
    updateObject = { ...updateObject, ...e };
  }

  if(getObjectValue(e, "products") && order.products.length > 0 && e.products.length > order.products.length) {
    Object.assign(updateObject, { "shippingInfoHasChanged": true, "rate": '', "selectRate": false, "shipping": false });
  }

  if(order.checkout && !e.hasOwnProperty("checkout")) {
    Object.assign(updateObject, { "selectRate": false, "checkout": false, "shipping": false });
  }
  // console.log(updateObject);

  setObjectFunc(setOrder, { ...updateObject });
};

export const getCartSessionFromLocalStorage = async () => {
  let orderObject = null;
  let decryptedData = await getEncryptedDataFromLocalStorage('order');
  if(decryptedData) {
    try {
      orderObject = JSON.parse(decryptedData);
      // console.log(orderObject);
    } catch (error) {
      log(error);
      localStorage.removeItem('order');
    }
  }
  return orderObject;
}

export const getCartSession = async () => {
  // Pull Cart Session From Local Storage
  let orderObject = await getCartSessionFromLocalStorage();

  // Pull Cart Session From Other Locations
  /* Insert Other Methods Here */

  // Return Cart Object
  return orderObject;
};

export const saveCartSession = (order) => {
  saveEncryptedDataToLocalStorage('order', JSON.stringify(order));
};

export const removeCartSession = (order) => {
  if(getObjectValue(order, "userId")) {
    // Insert Code to Remove Network Based Session
  }

  if (localStorage.getItem('order')) {
    localStorage.removeItem('order');
  }
};

export const deepEqual = (object1, object2) => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !deepEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }

  return true;
};

export const isObject = (object) => {
  return object != null && typeof object === 'object';
};

export const loadImage = (image, resize, maxWidth, maxHeight) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (upload) => {
      var img = new Image();
      img.src = upload.target.result;
      img.onload = async () => {
        let width = img.width;
        let height = img.height;

        let dataUri = null;
        if (resize && maxWidth && maxHeight && (width >= maxWidth || height >= maxHeight)) {
          dataUri = resizeImageAndGenerateBase64URI(image, maxWidth, maxHeight, "JPEG", 70);
        } else {
          dataUri = generateBase64ImageURI(image);
        }
        resolve(dataUri);
      };
    };
    reader.onerror = () => reject(reader.error);
    reader.readAsDataURL(image);
  });
};

export const resizeImageAndGenerateBase64URI = (image, maxWidth, maxHeight, fileType, quality) => {
  let dataUri = Resizer.imageFileResizer(image, maxWidth, maxHeight, fileType, quality, 0, "base64");
  return dataUri;
};

export const generateBase64ImageURI = async (image) => {
  let buffer = Buffer.from(await new Response(image).arrayBuffer());
  let dataUri = `data:${image.type};base64,${buffer.toString("base64")}`;

  return dataUri;
};

export const getJPEGMetaData = async (file) => {
  let fileToParse = file;
  if(typeof file === "string") {
    fileToParse = await dataURLtoFile(file, "test")
  }

  let response = false;
  try {
    response = await exifParse(fileToParse);
  } catch(error) {
    log(error, "Exif Parse Error:");
  }
  return response;
};

export const dataURLtoFile = (dataurl, filename) => {
  return new Promise((resolve) => {
      const arr = dataurl.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[arr.length - 1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while(n--){
        u8arr[n] = bstr.charCodeAt(n);
      }
      resolve(new File([u8arr], filename, { type: mime }));
  });
};

export const log = (item, name, hideLogs, isError) => {
  if(process.env.REACT_APP_NODE_ENV === "development") {
    if(name && !hideLogs && isError) {
      console.error(`${name}`, item);
    } else if (!hideLogs && isError) {
      console.error(item);
    } else if(name && !hideLogs) {
      console.log(`${name}`, item);
    } else if (!hideLogs) {
      console.log(item);
    }
  }
};

export const logTime = (name, end, hideLogs) => {
  if(process.env.REACT_APP_NODE_ENV === "development") {
    if(end && !hideLogs) {
      console.timeEnd(name);
    } else if(!hideLogs) {
      console.time(name);
    }
  }
};

export const simpleAmericanDate = (inputDate) => {
  const date = new Date(inputDate); // Create a new Date object with the current date
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 to get the correct month (0-indexed)
  const day = String(date.getDate()).padStart(2, '0');
  const year = date.getFullYear();
  const simpleDate = `${month}/${day}/${year}`;
  return simpleDate;
};

export const removeSecondsFromAmericanDate = (dateString) => {
  const date = new Date(dateString);
  
  // Check if the provided string is a valid date
  if (isNaN(date)) {
    return "Invalid date string";
  }

  // Remove seconds from the date
  const dateWithoutSeconds = new Date(date);
  dateWithoutSeconds.setSeconds(0);

  // Format the date without seconds to a string in MM/DD/YYYY HH:mm format
  const formattedDate = dateWithoutSeconds.toLocaleString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  });
  
  return formattedDate;
};

export const generateDatesArray = async (startDate, endDate) => {
  return new Promise((resolve, reject) => {
    const datesArray = [];
    let currentDate = new Date(startDate);

    if (currentDate > endDate) {
      reject("Start date is after the end date");
    } else {
      while (currentDate <= endDate) {
        datesArray.push(new Date(currentDate));
        currentDate.setHours(currentDate.getHours() + 1);
      }
      resolve(datesArray);
    }
  });
};