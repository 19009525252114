import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaAlignRight, FaShoppingCart } from "react-icons/fa";
import PropTypes from 'prop-types';

const logo = "https://vale-outdoors-prod-images.s3.us-east-2.amazonaws.com/Vale_Logo.png";
const user_pic = "https://vale-outdoors-prod-images.s3.us-east-2.amazonaws.com/user.png";

function Navbar({ isAdmin, isMobile, currentProfile }) {
    const [isOpen, setIsOpen] = useState(false);
  
    let navigate = useNavigate();

    const handleToggle =() => {
        if(isMobile) {
            if(!isOpen) {
                document.querySelector("#myNavbar").className = "w3-bar w3-white w3-wide w3-padding w3-card";
            } else {
                document.querySelector("#myNavbar").className = "w3-bar w3-wide w3-padding";
            }
            setIsOpen(!isOpen);
        }
    }

    useEffect(() => {
        handleScroll();
        
        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleScroll = () => {
        const position = window.pageYOffset;
        if (position < 20 && !isOpen) {
            document.querySelector("#myNavbar").className = "w3-bar w3-wide w3-padding";
        } else {
            document.querySelector("#myNavbar").className = "w3-bar w3-white w3-wide w3-padding w3-card";
        }
    };

    return (
        <nav className="navbar">
            <div className="nav-center">
                <div className="w3-top">
                    <div id="myNavbar" className="w3-bar w3-wide w3-padding w3-card">
                        <div style={isMobile ? { "display": "flex" } : {}}>
                            <Link className="w3-bar-item w3-button" to="/"  onClick={() => { if(isOpen) { handleToggle(); }}}>
                                <img
                                    style={{ "maxWidth": "25px" }}
                                    src={logo}
                                    className="nav-logo"
                                    alt="Vale Outdoors"
                                />
                            </Link>
                            <button
                                type="button"
                                style={{"width":"85%", "marginTop":"8px"}}
                                className="nav-btn"
                                onClick={() => handleToggle()}
                            >
                                <FaAlignRight className="nav-icon" />
                            </button>
                        </div>
                        <div className={isOpen ? "" : "w3-right w3-hide-small"}>
                            <div style={isOpen ? {"display":"flex", "flexWrap": "wrap", "justifyContent":"center", "width":"100%"} : {}}>
                                {isMobile && currentProfile && currentProfile.firstName ? (
                                    <Link to="/cart" className={isOpen ? "nav-links" : "w3-bar-item w3-button"} onClick={() => handleToggle()} style={{"paddingRight":"0px"}}>{currentProfile.firstName}</Link>
                                ) : isMobile && currentProfile && currentProfile.email ? (
                                    <Link to="/cart" className={isOpen ? "nav-links" : "w3-bar-item w3-button"} onClick={() => handleToggle()}>{currentProfile.email}</Link>
                                ) : null}
                                {isMobile && currentProfile ? (
                                    <img
                                        src={user_pic}
                                        className={isOpen ? "nav-links" : "w3-bar-item w3-button"}
                                        style={{"height": "40px", "paddingLeft":"8px"}}
                                        alt="Profile"
                                    />
                                ) : null}
                            </div>
                            {isMobile && !isOpen ? (
                                null
                            ) : (
                                <React.Fragment>
                                    <Link to="/equipment" className={isOpen ? "nav-links" : "w3-bar-item w3-button"} onClick={() => handleToggle()}>Equipment</Link>
                                    {currentProfile ? (
                                        <Link to={`/deer_vision/${currentProfile.sub}/locations`} className={isOpen ? "nav-links" : "w3-bar-item w3-button"} onClick={() => handleToggle()}>Deer Vision</Link>
                                    ) : (
                                        <React.Fragment>
                                            <Link to="/deer_vision" className={isOpen ? "nav-links" : "w3-bar-item w3-button"} onClick={() => handleToggle()}>Deer Vision</Link>
                                            <button onClick={() => navigate('/cart') } style={isMobile ? { "height": "38.5px", "width": "100%" } : { "height": "38.5px", "marginTop": "1.5px", "paddingLeft": "16px", "paddingRight": "8px" }} className={isOpen ? "nav-links" : "w3-bar-item w3-button"} id="cart-link">
                                                <FaShoppingCart style={{"transform": "scale(1.5)"}} /><span style={isMobile ? { "marginLeft": "16px" } : { "marginLeft": "16px", "marginRight": "16px" }}>Cart</span>
                                            </button>
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            )}
                            {isMobile && isOpen && currentProfile ? (
                                <React.Fragment>
                                    {isAdmin ? (
                                        <React.Fragment>
                                            <Link to="/add_distributor" className={isOpen ? "nav-links" : "w3-bar-item w3-button"} onClick={() => handleToggle()}>Add Distributor</Link>
                                            <Link to="/edit_distributor" className={isOpen ? "nav-links" : "w3-bar-item w3-button"} onClick={() => handleToggle()}>Edit Distributor</Link>
                                        </React.Fragment>
                                    ) : null}
                                    <Link to={`/account/${currentProfile.sub}`} className={isOpen ? "nav-links" : "w3-bar-item w3-button"} onClick={() => handleToggle()}>Settings</Link>
                                    <Link to="/logout" className={isOpen ? "nav-links" : "w3-bar-item w3-button"} onClick={() => handleToggle()}>Logout</Link>
                                </React.Fragment>
                            ) : isMobile && isOpen ? (
                                <React.Fragment>
                                    <Link to="/register" className={isOpen ? "nav-links" : "w3-bar-item w3-button"} onClick={() => handleToggle()}>Register</Link>
                                    <Link to="/login" className={isOpen ? "nav-links" : "w3-bar-item w3-button"} onClick={() => handleToggle()}>Login</Link>
                                </React.Fragment>
                            ) : null}
                            <div style={isOpen ? {"display":"flex", "flexWrap": "wrap", "justifyContent":"center"} : {}} className="dropdown">
                                {!isMobile && currentProfile && currentProfile.firstName ? (
                                    <Link className={isOpen ? "nav-links" : "w3-bar-item w3-button"} to="/cart" onClick={() => handleToggle()} style={{ "paddingRight": "0px" }}>{currentProfile.firstName}</Link>
                                ) : !isMobile && currentProfile && currentProfile.email ? (
                                    <Link className={isOpen ? "nav-links" : "w3-bar-item w3-button"} to="/cart" onClick={() => handleToggle()}>{currentProfile.email}</Link>
                                ) : null}
                                {!currentProfile && isOpen ? null : isMobile ? null : (
                                    <img
                                        src={user_pic}
                                        className={isOpen ? "nav-links" : "w3-bar-item w3-button"}
                                        style={{"height": "40px", "paddingLeft":"8px"}}
                                        alt="Profile"
                                    />
                                )}
                                <button className="dropbtn" />
                                <div className="dropdown-content w3-white">
                                    {currentProfile ? (
                                        <React.Fragment>
                                            <Link to={`/account/${currentProfile.sub}`}>Settings</Link>
                                            {isAdmin ? (
                                                <React.Fragment>
                                                    <Link to="/add_distributor">Add Distributor</Link>
                                                    <Link to="/edit_distributor">Edit Distributor</Link>
                                                </React.Fragment>
                                            ) : null}
                                            <Link to="/logout">Logout</Link>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <Link to="/register" onClick={() => handleToggle()}>Register</Link>
                                            <Link to="/login" onClick={() => handleToggle()}>Login</Link>
                                        </React.Fragment>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
}

Navbar.propTypes = {
    isAdmin: PropTypes.bool,
    isMobile: PropTypes.bool
};  

export default Navbar;