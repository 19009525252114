import React from "react";
import PropTypes from 'prop-types';
import ScrollButton from "../components/ScrollButton";
import ProductContainer from "../components/ProductContainer";

const logo = 'https://vale-outdoors-prod-images.s3.us-east-2.amazonaws.com/Vale_Logo.png';
const backgroundImage = 'https://vale-outdoors-prod-images.s3.us-east-2.amazonaws.com/new-background-compressed.png';

function Home({ isMobile, products }) {
    return (
        <>
            <div style={isMobile ? { "height": "100vh", "width":"100%", "overflow":"hidden" } : { "minHeight": "100%", "width":"100%" }} className="w3-display-container w3-opacity-min" id="home">
                <img style={isMobile ? { "filter": "grayscale(50%)", "height": "100%" } : { "width": "100%", "maxHeight": "100vh", "filter": "grayscale(50%)" }} alt="Site Banner" src={backgroundImage} />
                <div className="w3-display-middle" style={{ "whiteSpace": "nowrap", "display": "flex", "textAlign": "center" }}>
                    <div>
                        <span style={{ "padding":"24px 24px 32px 24px" }} className="w3-center w3-xlarge w3-wide w3-animate-opacity middle-logo forest-green">V<img style={{ "maxWidth": "75px", "marginRight": "6px" }} alt="Vale Outdoors Logo" src={logo} />LE</span>
                        <div>
                            <p className="forest-green" style={isMobile ? { "marginLeft": "16px", "marginRight": "16px", "fontSize": "18px"} : { "fontSize": "25px" }}>OUTFITTER FOR THE AMERICAN HUNTER</p>
                        </div>
                    </div>
                </div>
            </div>
            {isMobile ? null : (
                <ScrollButton anchor="equipment" text="Equipment" />
            )}
            <div style={isMobile ? {} : { "width": "100%", "display": "flex", "justifyContent": "center" }}>
                <div style={isMobile ? { "width": "100%" } : { "width": "75%" }} id="equipment">
                    <div style={{ "minHeight": "400px", "display": "flex", "alignItems": "center", "justifyContent": "center" }}>
                        <div>
                            <h1 style={{ "marginBottom": "32px", "marginLeft": "16px" }}>Equipment</h1>
                            {/* Product grid */}
                            {products && products.length > 0 ? (
                                <div className="w3-container w3-row">
                                    <ProductContainer isMobile={isMobile} products={products} />
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

Home.propTypes = {
    isMobile: PropTypes.bool
};  

export default Home;